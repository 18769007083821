import React from 'react';

import { UserAvatarLinkUser } from '../../../../../../helpers/UserAvatarLink';

import { RemovableUserAvatarLink } from '../../../../../../helpers/RemovableUserAvatarLink';

interface SelectedUsersListProps {
  onDeselectUser: (user: UserAvatarLinkUser) => void;
  selectedUsers: UserAvatarLinkUser[];
}
function SelectedUsersList({
  onDeselectUser,
  selectedUsers
}: SelectedUsersListProps) {
  return (
    <div className="sticky bottom-0 border-t dark:border-gray-700 p-4 pb-0 mt-4 -mx-4 bg-white dark:bg-gray-850 overflow-y-hidden overflow-x-auto flex space-x-3">
      {selectedUsers.map((selectedUser) => (
        <RemovableUserAvatarLink
          avatarType="main"
          key={`selected-user-${selectedUser.nanoId}`}
          onRemove={onDeselectUser}
          user={selectedUser}
        />
      ))}
    </div>
  );
}
export default SelectedUsersList;
