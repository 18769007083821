import { ClassName } from '../../../../../../../types';

import { MessagesListMessageFileAttachmentButtons } from '../../buttons/MessagesListMessageFileAttachmentButtons';
import {
  MessagesListMessageImageFileAttachmentAttachment,
  MessagesListMessageImageHandleOpenLightbox
} from '../MessagesListMessageImage';
import { MessagesListMessageImageFileAttachmentMessage } from './MessagesListMessageImageFileAttachment.types';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';

import { getFileAttachmentName } from '../../../../../../fileAttachments/utils/getFileAttachmentName';

interface MessagesListMessageImageFileAttachmentProps {
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: MessagesListMessageImageFileAttachmentAttachment;
  message: MessagesListMessageImageFileAttachmentMessage;
  handleOpenLightbox: MessagesListMessageImageHandleOpenLightbox;
  tooltipSingleton?: boolean;
}

function MessagesListMessageImageFileAttachment({
  linkClassName,
  imageClassName,
  attachment,
  message,
  handleOpenLightbox,
  tooltipSingleton
}: MessagesListMessageImageFileAttachmentProps) {
  return (
    <div className={linkClassName}>
      <MessagesListMessageFileAttachmentButtons
        addClassName="top-0"
        fileAttachment={attachment}
        message={message}
      />
      <LinkHelper
        href={attachment.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.file}
          alt={getFileAttachmentName(attachment)}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
          tooltipSingleton={tooltipSingleton}
        />
        {(attachment.drawing || attachment.reference || attachment.texture) && (
          <div className="absolute bottom-0 left-0 p-1 bg-gray-600 text-white flex rounded-bl rounded-tr">
            <div className="text-sm w-5 h-max text-center">
              {attachment.drawing ? <span>D</span> : null}
              {attachment.reference ? <span>R</span> : null}
              {attachment.texture ? <span>T</span> : null}
            </div>
          </div>
        )}
      </LinkHelper>
    </div>
  );
}

export default MessagesListMessageImageFileAttachment;
