import React, { useState } from 'react';
import {
  MessagesListMessageFileFileAttachment,
  MessagesListMessageFileMessage
} from './MessagesListMessageFile.types';

import { MessagesListMessageFileAttachmentButtons } from '../../buttons/MessagesListMessageFileAttachmentButtons';

import { Icon } from '../../../../../../../helpers/Icon';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../../../../../../helpers/tooltips/TooltipSingletonTarget';

import { getFileIcon } from '../../../../../../../utils/getFileIcon';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { getFileAttachmentName } from '../../../../../../fileAttachments/utils/getFileAttachmentName';

interface MessagesListMessageFileProps {
  fileAttachment: MessagesListMessageFileFileAttachment;
  message: MessagesListMessageFileMessage;
  tooltipSingleton?: boolean;
}

function MessagesListMessageFile({
  fileAttachment,
  message,
  tooltipSingleton
}: MessagesListMessageFileProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const fileName = getFileAttachmentName(fileAttachment);

  const tooltipView = fileName ? (
    <Tooltip
      placement={TooltipPlacement.TOP}
      referenceElement={referenceTooltipElement}
      className="break-words"
      withArrow
    >
      <div className="break-words">{fileName}</div>
    </Tooltip>
  ) : null;

  const tooltipSingletonView = fileName ? (
    <TooltipSingletonTarget referenceElement={referenceTooltipElement}>
      <div className="break-words">{fileName}</div>
    </TooltipSingletonTarget>
  ) : null;

  return (
    <div
      className="h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item max-w-48 pl-2  py-1.5 items-center flex"
      ref={setReferenceTooltipElement}
    >
      <MessagesListMessageFileAttachmentButtons
        addClassName="top-0"
        fileAttachment={fileAttachment}
        message={message}
      />

      <div className="absolute top-0 left-0 h-full flex items-center px-2">
        <Icon icon={getFileIcon(fileAttachment.file)} className="h-6 w-6" />
      </div>

      <LinkHelper
        className="line-clamp-3 text-sm break-words leading-tight pl-8 rounded pr-3.5"
        href={fileAttachment.file}
        target="_blank"
      >
        {fileName}
      </LinkHelper>

      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </div>
  );
}

export default MessagesListMessageFile;
